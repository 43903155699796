.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.fixed-header {
  top: 0;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}
.flex-container > div {
  background-color: #f1f1f1;
  width: 240px;
  margin: 10px;
  text-align: center;
  line-height: 32px;
}
.flex-container > div > input {
  margin-right: 200px;
}

.delete-checkbox {
  margin-left: 10px;
}

#save_button {
  margin-top: 10px;
}

#cancel_button {
  margin-top: 10px;
}

#add_button {
  margin-top: 10px;
}

#delete-product-btn {
  margin-top: 10px;
}

/*# sourceMappingURL=custom.css.map */
